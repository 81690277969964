<template>
  <div class="verify-email">
    <div class="content verify-email__content">
      <div class="content-image">
        <img
          src="@/assets/images/computerImage.png"
          alt="Computer image"
          class="content-image__image"
        />
      </div>
      <div class="message content__message">
        <h1 class="message__tittle">Verify your email to proceed</h1>
        <p class="message__sub-tittle">
          We just sent an email to the address: {{email}} <br>
          Please check your email and click on the link provided to verify your address.
        </p>
      </div>
      <button class="content__button"
      @click="checkForm()">Resend verification email</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerifyEmail',
  data() {
    return {
      email: localStorage.getItem('brains-register-email'),
    };
  },
  methods: {
    async sendEmail(email) {
      const response = await this.$store.dispatch('freelancer/sendEmailToken', email);
      if (response.length === 0) {
        this.$router.push({ path: '/verify-email' });
      } else {
        this.$toast.add({
          severity: 'error',
          summary: response,
          detail: 'Error sending confirmation email',
          life: 4000,
        });
      }
    },
    checkForm() {
      this.sendEmail(this.email);
    },
  },
};
</script>

<style lang="scss" scoped>
.verify-email {
  display: grid;

  &__content {
    display: flex;
    justify-items: center;
    flex-direction: column;
  }
}

.content {
  &__button {
    @include secondary-button();
    margin: 0 auto;
    width: 50%;
  }
  &__message {
    display: grid;
    justify-items: center;
  }
}

.content-image {
  width: 100%;
  display: flex;
  justify-content: center;
  &__image{
    width: 50%;
  }
}

.message {
  &__tittle {
    font-size: 2rem;
    margin-bottom: .5rem;
  }
  &__sub-tittle {
    margin: 3rem 0 3rem 0;
  }
}
</style>
